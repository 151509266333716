<template>
	<div class="map-controller">
		<div class="map-tabs bg-white border-bottom">
			<div class="header mb-0">
				<div class="container-fluid">
					<div class="header-body border-0 py-3">
						<div class="row align-items-end">
							<div class="col">
								<h4 class="header-title">Route Searches</h4>
							</div>
							<div class="col-auto">
								<v-datepicker range-separator=" to " v-model="filter.range" placholder="Filter by date"
									range></v-datepicker>
								<button @click.prevent="applyFilter" class="btn btn-primary ml-2 btn-sm">
									Apply
								</button>
							</div>
						</div>
						<!-- / .row -->
					</div>
					<!-- / .header-body -->
				</div>
			</div>
			<div class="container-fluid">
				<div class="row mt-3">
					<div class="col">
						<div>
							<ul class="nav nav-pills mb-1">
								<li class="nav-item">
									<router-link :to="{
										name: 'route-searches.filtered-review',
										params: {
											filterType: 'traffic-flow',
										},
									}" class="nav-link" active-class="active">Traffic Flow</router-link>
								</li>

								<li class="nav-item">
									<router-link class="nav-link" active-class="active" :to="{
										name: 'route-searches.filtered-review',
										params: {
											filterType: 'top-origins',
										},
									}">Top Origins</router-link>
								</li>

								<li class="nav-item">
									<router-link class="nav-link" active-class="active" :to="{
										name: 'route-searches.filtered-review',
										params: {
											filterType: 'top-destinations',
										},
									}">Top Destinations</router-link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<VueDeckgl :layers="layers" :viewState="viewState" @click="handleClick" :getTooltip="getTooltip"
			@view-state-change="updateViewState" ref="vueDeckGl">
			<div id="map" ref="map"></div>
		</VueDeckgl>
	</div>
</template>

<script setup>
import { ref, onMounted, watch, computed, nextTick } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import { useRoute } from 'vue-router/composables'
import { h3ToGeo } from 'h3-js'
import { Deck } from '@deck.gl/core'
import { GeoJsonLayer, ArcLayer } from '@deck.gl/layers'
import { HexagonLayer, HeatmapLayer } from '@deck.gl/aggregation-layers'
import mapboxgl from 'mapbox-gl'
import moment from 'moment'
// import VueDeckgl from "vue-deck.gl";
import VueDeckgl from '@/components/core/VueDeckGl.vue'

const route = useRoute()
const props = defineProps({
  filterType: {
    type : String
  }
})


const MAP_STYLE = 'mapbox://styles/akshuttlers/cl3k94bni001d14npbwn6pz49'

const errorLoading = ref(false)
const loading = ref(false)
const filter = ref({
  areaHashInMeters: 300,
  range: [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
})
const map = ref(null)
const data = ref({})
const accessToken = ref(process.env.VUE_APP_MAPBOX_TOKEN)
const mapStyle = ref(MAP_STYLE)
const viewState = ref({
  latitude: 6.43106879301731,
  longitude: 3.468537700546043,
  zoom: 4,
  bearing: 0,
  pitch: 30
})

onMounted(() => {
  drawMap()
})

map.value = null

const isTrafficFlow = computed(() => { return props.filterType == 'traffic-flow' })
const isTopOrigins = computed(() => { return props.filterType === 'top-origins' })
const isTopDestinations = computed(() => { return props.filterType === 'top-destinations' })
const layers = computed(() => { return getMapLayers() })

const getTooltip = ({ object, layer }) => {
  if (!object) return null
  if (isTrafficFlow.value) {
    if (layer && layer.id === 'trips') {
      if (object.OriginHash && object.DestinationHash) {
        return `From ${object.OriginHash} To ${object.DestinationHash}`
      }
    }

    if (object.properties && object.properties.name) {
      return `Area ${object.properties.name}`
    }
  }

  if (isTopOrigins.value || isTopDestinations.value) {
    if (object.points) {
      const label = object.points.map((item) => {
        return item.source.OriginHash
      })

      const count = object.points.map((item) => {
        return item.source.Count
      })

      return `Area ${label.join(', ')} Count: ${count.reduce((i, k) => i + k, 0)}`
    }
  }

  return null
}

const handleClick = ({ event, info }) => { }
const updateViewState = (viewState) => {
  viewState.value = {
    ...viewState
  }
  map.value.jumpTo({
    center: [viewState.longitude, viewState.latitude],
    zoom: viewState.zoom,
    bearing: viewState.bearing,
    pitch: viewState.pitch
  })
}
const applyFilter = () => {
  fetchRouteSearchHistory()
}
const updateMap = () => { }
const drawMap = () => {
  // const vm = this
  mapboxgl.accessToken = accessToken.value
  map.value = new mapboxgl.Map({
    container: map.value,
    style: MAP_STYLE,
    interactive: false,
    center: [viewState.value.longitude, viewState.value.latitude],
    zoom: viewState.value.zoom,
    bearing: viewState.value.bearing,
    pitch: viewState.value.pitch
  })
  setTimeout(() => {
    const { latitude, longitude, pitch, bearing, zoom } = viewState.value
    viewState.value = {
      latitude,
      longitude,
      pitch,
      bearing,
      zoom: 12,
      transitionDuration: 3000
    }
  }, 5000)
}

const getMapLayers = () => {
  if (!data.value) return []
  if (!data.value.destination_tally) return []
  if (!data.value.origin_tally) return []

  const layers = []

  const vm = this
  let pointsData = [...data.value.destination_tally, ...data.value.origin_tally]
  pointsData = pointsData.map((i) => {
    const d = h3ToGeo(i.OriginHash)
    return {
      type: 'Feature',
      properties: {
        name: i.OriginHash,
        count: i.Count
      },
      geometry: {
        type: 'Point',
        coordinates: [d[1], d[0]]
      }
    }
  })

  if (isTrafficFlow.value) {
    const geoJsonLayer = new GeoJsonLayer({
      id: 'trip-areas',
      data: {
        type: 'FeatureCollection',
        features: pointsData
      },
      // Styles
      filled: true,
      pointRadiusMinPixels: 2,
      pointRadiusScale: 1,
      getPointRadius: filter.value.areaHashInMeters || 300, // (f) => f.Count,
      getFillColor: [200, 0, 80, 180],
      // Interactive props
      pickable: true,
      autoHighlight: true,
      onClick: (info) => {
        if (info.object) {
          alert(`${info.object.properties.name} (${info.object.properties.abbrev})`)
        }
      }
    })

    const tripArcs = new ArcLayer({
      id: 'trips',
      data: data.value.origin_to_destination_tally,
      pickable: true,
      // dataTransform: (d) => d.features.filter((f) => f.properties.scalerank < 4),
      // Styles
      getSourcePosition: (f) => {
        const d = h3ToGeo(f.OriginHash)
        return [d[1], d[0]]
      }, // London
      getTargetPosition: (f) => {
        const d = h3ToGeo(f.DestinationHash)
        return [d[1], d[0]]
      },
      getSourceColor: [0, 128, 200],
      getTargetColor: [200, 0, 80],
      getWidth: 2
    })

    layers.push(geoJsonLayer)
    layers.push(tripArcs)
  }

  if (isTopOrigins.value) {
    const topOriginLayer = new HeatmapLayer({
      id: 'origin-heatmap-layer',
      data: data.value.destination_tally,
      getPosition: (f) => {
        const d = h3ToGeo(f.OriginHash)
        return [d[1], d[0]]
      },
      getWeight: (f) => {
        return f.Count
      },
      aggregation: 'SUM'
    })

    const popularOriginsAreas = new HexagonLayer({
      id: 'popular-origins-layer',
      data: data.value.origin_tally || [],
      pickable: true,
      extruded: true,
      radius: filter.value.areaHashInMeters || 300,
      elevationScale: 2,
      getPosition: (f) => {
        const d = h3ToGeo(f.OriginHash)
        return [d[1], d[0]]
      }
    })

    // layers.push(popularOriginsAreas);
    layers.push(topOriginLayer)
  }

  if (isTopDestinations.value) {
    const topDestinationLayer = new HeatmapLayer({
      id: 'destination-heatmap-layer',
      data: data.value.destination_tally,
      getPosition: (f) => {
        const d = h3ToGeo(f.OriginHash)
        return [d[1], d[0]]
      },
      getWeight: (f) => {
        return f.Count
      },
      aggregation: 'SUM'
    })

    const popularDestinationsAreas = new HexagonLayer({
      id: 'popular-destinations-layer',
      data: data.value.destination_tally || [],
      pickable: true,
      extruded: true,
      radius: filter.value.areaHashInMeters || 300,
      elevationScale: 2,
      getPosition: (f) => {
        const d = h3ToGeo(f.OriginHash)
        return [d[1], d[0]]
      }
    })

    // layers.push(popularDestinationsAreas);
    layers.push(topDestinationLayer)
  }

  return layers
}

const fetchRouteSearchHistory = async () => {
  loading.value = true
  errorLoading.value = false

  const area = filter.value.areaHashInMeters || 300
  let startDate = filter.value.range.length ? filter.value.range[0] : null
  let endDate = filter.value.range.length > 1 ? filter.value.range[1] : null

  if (startDate) {
    startDate = moment(startDate).format('YYYY-MM-DD')
  }

  if (endDate) {
    endDate = moment(endDate).format('YYYY-MM-DD')
  }

  let query = ''

  if (startDate) {
    query = `${query}&startDate=${startDate}`
  }

  if (endDate) {
    query += `&endDate=${endDate}`
  }

  try {
    try {
      const res = await axiosInstance
        .get(`/v2/routes/search-history?areaHashInMeters=${area}${query}`)
      data.value = res.data
    } catch {
      errorLoading.value = true
    }
  } finally {
    return (loading.value = false)
  }
}

fetchRouteSearchHistory()

watch(() => route, () => {
  const startDate = route.query.startDate
  if (startDate) {
    const startDateObj = moment(startDate, 'YYYY-MM-DD').toDate()
    filter.value.range[0] = startDateObj
  }

  const endDate = route.query.endDate
  if (endDate) {
    const endDateObj = moment(endDate, 'YYYY-MM-DD').toDate()
    filter.value.range[1] = endDateObj
  }

  nextTick(() => {
    updateMap()
  })
})

watch(data, () => {
  nextTick(() => {
    updateMap()
  })
})

</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.map-controller {
  min-height: 100vh;
}

.map-controller>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.nav-pills .nav-link.active{
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show>.nav-link {
  border-radius: 0;
}

.map-tabs.bg-white.border-bottom {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 110px;
  z-index: 1000;
}

#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e5e9ec;
  overflow: hidden;
}
</style>
