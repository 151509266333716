var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"map-controller"},[_c('div',{staticClass:"map-tabs bg-white border-bottom"},[_c('div',{staticClass:"header mb-0"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"header-body border-0 py-3"},[_c('div',{staticClass:"row align-items-end"},[_vm._m(0),_c('div',{staticClass:"col-auto"},[_c('v-datepicker',{attrs:{"range-separator":" to ","placholder":"Filter by date","range":""},model:{value:(_setup.filter.range),callback:function ($$v) {_vm.$set(_setup.filter, "range", $$v)},expression:"filter.range"}}),_c('button',{staticClass:"btn btn-primary ml-2 btn-sm",on:{"click":function($event){$event.preventDefault();return _setup.applyFilter.apply(null, arguments)}}},[_vm._v(" Apply ")])],1)])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col"},[_c('div',[_c('ul',{staticClass:"nav nav-pills mb-1"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
									name: 'route-searches.filtered-review',
									params: {
										filterType: 'traffic-flow',
									},
								},"active-class":"active"}},[_vm._v("Traffic Flow")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
									name: 'route-searches.filtered-review',
									params: {
										filterType: 'top-origins',
									},
								}}},[_vm._v("Top Origins")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{
									name: 'route-searches.filtered-review',
									params: {
										filterType: 'top-destinations',
									},
								}}},[_vm._v("Top Destinations")])],1)])])])])])]),_c(_setup.VueDeckgl,{ref:"vueDeckGl",attrs:{"layers":_setup.layers,"viewState":_setup.viewState,"getTooltip":_setup.getTooltip},on:{"click":_setup.handleClick,"view-state-change":_setup.updateViewState}},[_c('div',{ref:"map",attrs:{"id":"map"}})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col"},[_c('h4',{staticClass:"header-title"},[_vm._v("Route Searches")])])
}]

export { render, staticRenderFns }